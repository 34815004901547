<script setup lang="ts">
const hideScroll = ref(false);

onMounted(() => {
  window.addEventListener("scroll", () => (hideScroll.value = true));
  window.addEventListener("scrollend", onScrollend);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", () => (hideScroll.value = true));
  window.removeEventListener("scrollend", onScrollend);
});

function onScrollend() {
  const totalPageHeight = document.body.scrollHeight;
  const scrollPoint = window.scrollY + window.innerHeight;
  hideScroll.value = !(scrollPoint < totalPageHeight - screen.height / 2);
}
</script>

<template>
  <Transition name="fade">
    <button v-if="!hideScroll" class="scroll-down">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
      >
        <circle cx="32.5" cy="32.5" r="0.5" fill="white" />
        <circle cx="33" cy="33" r="31" stroke="white" stroke-width="4" />
        <path
          d="M35 18C35 16.8954 34.1046 16 33 16C31.8954 16 31 16.8954 31 18L35 18ZM31.5858 50.4142C32.3668 51.1953 33.6332 51.1953 34.4142 50.4142L47.1421 37.6863C47.9232 36.9052 47.9232 35.6389 47.1421 34.8579C46.3611 34.0768 45.0948 34.0768 44.3137 34.8579L33 46.1716L21.6863 34.8579C20.9052 34.0768 19.6389 34.0768 18.8579 34.8579C18.0768 35.6389 18.0768 36.9052 18.8579 37.6863L31.5858 50.4142ZM31 18L31 49L35 49L35 18L31 18Z"
          fill="white"
        />
      </svg>
    </button>
  </Transition>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
@use "@/assets/scss/transitions";
@include transitions.fade;
button.scroll-down {
  background: transparent;
  border: none;
  bottom: 10px;
  cursor: pointer;

  display: none;

  height: 66px;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  width: 66px;
  z-index: 100;
  @include queries.for-size(desktop-up) {
    display: inline-block;
  }
}
</style>
