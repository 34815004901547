<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();

const inView = ref(false);
const splitImage = ref<HTMLElement>();
let itemObserver: IntersectionObserver | null = null;

const sliderLeftTop = ref(-1000);
const sliderRightTop = ref(1000);

onMounted(() => {
  itemObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          inView.value = true;
          onWatchScroll();
        } else {
          inView.value = false;
        }
      });
    },
    {
      threshold: 0.2,
    }
  );

  itemObserver.observe(splitImage.value!);
});

function onWatchScroll() {
  if (inView.value) {
    const windowOffsetHeight = window.innerHeight - 90;
    const percentTop = (splitImage.value!.getBoundingClientRect().top - 90) / windowOffsetHeight;

    if (percentTop < 0.2) {
      sliderLeftTop.value = 0;
      sliderRightTop.value = 0;
    } else {
      if (percentTop >= 0.2 && percentTop <= 0.8) {
        sliderLeftTop.value = percentTop * windowOffsetHeight;
        sliderRightTop.value = splitImage.value!.getBoundingClientRect().bottom * percentTop * -1;
      } else if (percentTop < 0.7) {
        sliderLeftTop.value = splitImage.value!.offsetHeight * percentTop;
        sliderRightTop.value = sliderLeftTop.value * -2;
      }
    }

    requestAnimationFrame(onWatchScroll);
  }
}
</script>

<template>
  <div ref="splitImage" class="split-image">
    <div>
      <div class="split-slider">
        <div
          class="slider-left"
          :style="{
            transform: `translateY(${sliderLeftTop}px)`,
          }"
        >
          <img :src="attrs.src as string" />
        </div>

        <div
          class="slider-right"
          :style="{
            transform: `translateY(${sliderRightTop}px)`,
          }"
        >
          <img :src="attrs.src as string" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.split-image {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .split-slider {
    height: 100%;
    position: absolute;
    width: 100%;

    > div {
      display: flex;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      transition: all 0.75s ease-out;

      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
      }

      &.slider-left {
        mask-image: linear-gradient(90deg, #000 50%, rgba(0, 0, 0, 0) 50%);
      }

      &.slider-right {
        mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 50%, #000 50%);
      }
    }
  }
}
</style>
