<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
});

const backgroundVideo = ref<HTMLVideoElement>();
let itemObserver: IntersectionObserver | null = null;

onMounted(() => {
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      backgroundVideo.value!.play();
    } else {
      backgroundVideo.value!.pause();
    }
  });

  itemObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        backgroundVideo.value!.play();
      } else {
        backgroundVideo.value!.pause();
      }
    });
  });

  itemObserver.observe(backgroundVideo.value!);
});
</script>

<template>
  <div class="background-video">
    <video ref="backgroundVideo" v-bind="$attrs" autoplay muted loop playsinline></video>
  </div>
</template>

<style lang="scss" scoped>
.background-video {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  video {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>
