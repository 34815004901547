<script setup lang="ts">
const showStayInTheLoop = ref(false);
</script>

<template>
  <div class="feature times-travel-feature">
    <div class="feature-content">
      <img
        :src="`${useCdnUrl()}/assets-2024/times-travel/times-travel-logo.png`"
        alt="Times Travel"
      />
      <h2>+ Viewing Deck</h2>
      <p>
        Experience time in unexpected ways and journey through decades of change. In some moments,
        <i>time flies</i> and in others it's <i>eternal midnight</i> - all leading to a spectacular
        360-degree viewing deck above Times Square, with an elevated perspective of the New Year's
        Eve Ball and the city.
      </p>
      <TheButton to="#" class="times-travel-callout" @click="showStayInTheLoop = true"
        >Join the waitlist</TheButton
      >
      <p class="coming-soon">Join the waitlist and be the first to know when ticket sales open</p>
    </div>

    <div class="feature-image-effect">
      <SplitImage
        :src="`${useCdnUrl()}/assets-2024/times-travel/times-travel-feature-placeholder.png`"
      />
    </div>
    <div
      class="feature-image-mobile"
      :style="{
        'background-image': `url(${useCdnUrl()}/assets-2024/times-travel/times-travel-feature-placeholder.png)`,
      }"
    />
  </div>

  <Teleport to="body">
    <TheModal v-if="showStayInTheLoop" @close-modal="showStayInTheLoop = false">
      <template #content>
        <FormsSignUp @close-modal="showStayInTheLoop = false" />
      </template>
    </TheModal>
  </Teleport>
</template>

<style lang="scss" scoped>
.times-travel-feature {
  .feature-content {
    background-color: #511a44;

    h2 {
      line-height: 1;
      margin: 0;
    }

    .times-travel-callout {
      background-color: #3a1331;
      border-width: 0;

      &:hover {
        color: #3a1331;
      }
    }
  }
}
</style>
